import { useSelector } from "react-redux"
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ReactBeforeSliderComponent from 'react-before-after-slider-component';
import { useState } from "react";
const BeforeAfter = () => {
    const FIRST_IMAGE = {
        imageUrl: 'https://img1.wsimg.com/isteam/ip/ba31b9b7-2666-4dc0-b8a2-34b190f3bdb7/Actual-Before-1-1.jpg/:/cr=t:0%25,l:0%25,w:100%25,h:100%25/rs=w:2320,h:1305'
    };
    const SECOND_IMAGE = {
        imageUrl: 'https://img1.wsimg.com/isteam/ip/ba31b9b7-2666-4dc0-b8a2-34b190f3bdb7/Before1-1.jpg/:/cr=t:0%25,l:0%25,w:100%25,h:100%25/rs=w:2320,h:1305'
    };
    const delimiterIconStyles = {
        width: '46px',
        height: '46px',
        backgroundSize: 'cover',
        borderRadius: 'none',
        backgroundImage: `url('/assets/icon.svg')`,
        boxShadow: '0 8px 16px 0 rgba(0, 0, 0, 0.2)',
    }

    const [navVisible, setNavVisible] = useState(false);
    const [currentPercent, setCurrentPercent] = useState(50);

    const images = [
        {
            firstImage: {
                imageUrl: '/assets/homeAfter.jpg',
            },
            secondImage: {
                imageUrl: '/assets/homeBefore.jpg',
            }

        },
        /*    {
               firstImage: {
                   imageUrl: '/assets/commercialBefore.jpg',
               },
               secondImage: {
                   imageUrl: '/assets/commercialAfter.jpeg',
               }
           }, */
        {
            firstImage: {
                imageUrl: '/assets/parkingLotBefore.png',
            },
            secondImage: {
                imageUrl: '/assets/parkingLotAfter.png',
            }

        },

    ];

    const [currentSlide, setCurrentSlide] = useState(0);

    const nextSlide = () => {
        setCurrentSlide((prev) => (prev + 1) % images.length);
    };

    const prevSlide = () => {
        setCurrentSlide((prev) => (prev - 1 + images.length) % images.length);
    };
    const handleDotClick = (index) => {
        setCurrentSlide(index);
    };


    useEffect(() => {
        // Pre-fetch images
        if (images && images.length > 0) {
            images.forEach(imageSet => {
                const firstImage = new Image();
                firstImage.src = imageSet.firstImage.imageUrl;

                const secondImage = new Image();
                secondImage.src = imageSet.secondImage.imageUrl;
            });
        }
    }, [images]);

    return (
        <>
            <div className="split-container">
                <div className="split-text">
                    <div className="core-text">
                        <span>Unmatched Cleaning</span>
                        <h1>Before & After</h1>
                        <p>Elevate Your Property’s Appeal with Our Advanced Drone-Assisted Pressure and Soft Washing Services!</p>
                    </div>
                    <div className="core-list">
                        <ul>
                            <li><i className="material-icons">check_circle_outline</i>Efficient & Fast Cleaning</li>
                            <li><i className="material-icons">check_circle_outline</i>Safe: No more ladders or platforms</li>
                            <li><i className="material-icons">check_circle_outline</i>Reach Inaccessible Areas</li>
                        </ul>
                    </div>
                </div>
                <div className="before-after-slider-container">
                    <div className="flyby-before-after">
                        <ReactBeforeSliderComponent
                            firstImage={images[currentSlide].firstImage}
                            secondImage={images[currentSlide].secondImage}
                            width={1000}
                            height={600}
                            delimiterIconStyles={delimiterIconStyles}
                            currentPercentPosition={currentPercent}
                            onChangePercentPosition={
                                (percent) => {
                                    setCurrentPercent(percent);
                                    setNavVisible(false)
                                }
                            }

                        />
                    </div>
                    <div className="navigation-dots">
                        {images.map((_, index) => (
                            <span onMouseEnter={
                                () => handleDotClick(index)
                            } key={index}
                                className={`dot ${index === currentSlide ? 'active' : ''}`}
                                onClick={() => handleDotClick(index)}></span>
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}

export default BeforeAfter