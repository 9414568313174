import { useSelector } from "react-redux"
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import CountUp from 'react-countup';
import { useRef } from "react";
import { useInView } from "react-intersection-observer";

const StatsCard = () => {

    const [isVisible, setIsVisible] = useState(false);
    const statsRef = useRef(null);
    const [observerHandler, setObserverHandler] = useState(false);
    const { ref, inView, entry } = useInView({
        threshold: .5      });

    useEffect(() => {
       if(inView == true && isVisible == false){
        setIsVisible(true)
       }
    }, [inView]);

    return (
        <div ref={ref} className="chunker-container">
            <div className="chunker-hero">
                <img src="/assets/drone2.png" alt="Image of a Drone Spraying a Solar Panel" />
            </div>
            <div className="chunker-content" >
                <div className="chunklet" >

                    {isVisible && <CountUp start={0} end={5} duration={1.1} suffix="x" />}

                    <h2>Faster Cleaning</h2>
                </div>
                <span className="chunklet-divider"></span>
                <div className="chunklet" >

                    {isVisible && <CountUp start={0} end={50} duration={1.1} suffix="%" delay={0.5} />}

                    <h2>Less Water</h2>
                </div>
                <span className="chunklet-divider"></span>
                <div className="chunklet" >

                    {isVisible && <CountUp start={0} end={22710} duration={1.1} suffix="" delay={1} />}

                    <h2>Ladder Injuries Avoided</h2>
                    <p>Per Year</p>

                </div>
            </div>
        </div>
    );
}

export default StatsCard