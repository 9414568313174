import React, { useEffect, useRef } from 'react';

import { Accordion } from '@mantine/core';
import classes from '../../css/item.module.css';
import { analyticsCall } from '../../helpers/analytics';
import { useState } from 'react';



const FAQ = (props) => {
    const groceries = [
        {
            value: 'What are your service hours?',
            description:
                'Our service hours are Monday to Saturday from 08:00 AM to 05:00 PM. Keep in mind our operating hours for cleaning services can be personalized to fit your needs.',
        },
        {
            value: 'Do you offer services not listed on your website?',
            description:
                `We offer a range of services including Window Cleaning, Roof Cleaning, Driveway Pressure Washing, and more. Let's talk we might have services that are not listed here.`,
        },
        {
            value: 'What locations do you service?',
            description:
                "We operate nationwide. We are based in Madison, Mississippi, but we are happy to travel to your location. Contact us to discuss your needs.",
        },
        {
            value: 'How do I schedule an appointment for your services?',
            description: "Please feel free to Call, Email or Book a consultation with us on our website. We will get back to you as soon as possible.",
        },
        {
            value: 'Is our business really veteran-owned?',
            description: "Yes, we are a veteran-owned business. We are proud to have served our country and now we are proud to serve our community.",
        },
        {
            value: 'What payment methods do you accept?',
            description: "We accept Credit Card, Debit Card, Cash, Bank Transfer, Check, and E-Transfer.",
        }
    ];

    const [openedItem, setOpenedItem] = useState(null);

    const handleAccordionChange = (value) => {
        if (value !== openedItem) {
            analyticsCall({
                category: "FAQ - Click",
                action: "FAQ Accordion Click - " + value ? "Opened" : "Closed",
                label: value
            })
            setOpenedItem(value);
        }
    };


    const items = groceries.map((item) => (
        <Accordion.Item key={item.value} value={item.value}>
            <Accordion.Control>{item.value}</Accordion.Control>
            <Accordion.Panel>
                <p style={{ padding: '0px 13px 13px 13px', maxWidth: '1000px' }}>
                    {item.description}
                </p>
            </Accordion.Panel>
        </Accordion.Item>
    ));

    return (
        <>


            <div className='accordion-container'>
                <div className="core-header">
                    <h1>Frequently Asked Questions</h1>
                    <p>You've got questions, we've got the answers!</p>
                </div>
                <Accordion
                    classNames={{ chevron: classes.chevron }}
                    chevron={
                        <div
                            style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}
                            className={classes.icon}
                        >
                            <i style={{ fontSize: '33px', color: 'var(--main-color)' }} className='material-icons-round'>add</i>
                        </div>
                    }
                    variant="contained"
                    onChange={handleAccordionChange}
                >
                    {items}
                </Accordion>
            </div>
        </>
    );
};

export default FAQ