import { useSelector } from "react-redux"
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ReactBeforeSliderComponent from 'react-before-after-slider-component';
import { useState } from "react";
import CountUp from 'react-countup';
import { useRef } from "react";
import { analyticsCall } from "../../helpers/analytics";
const Services = () => {



    return (
        <>
            <div className="service-container">
                <div className="core-header">
                    <span>Our Services</span>
                    <h1>What Can We Clean?</h1>
                    <p>The real question is what can't we clean.</p>
                </div>

                <div className="service-cards">

                    <Link to={'/drone-softwash-pressurewash'} className="service-card" data-aos="fade-up">
                        <img src="/assets/commercial.png" alt="A drone cleaning a commercial building" />
                        <h2>Drone Soft Wash & Pressure Wash</h2>
                        <p>Elevate Your Property's Appeal with Our Drone-Assisted Pressure and Soft Washing Services!</p></Link>

                    <Link to={'/multi-story-window-cleaning'} className="service-card" data-aos="fade-up" data-aos-delay="100">


                        <img src="/assets/multistorycropped.png" alt="A drone cleaning a multi story building" />
                        <h2>Multi-Story Window Cleaning</h2>
                        <p>Our innovative drone technology is redefining the landscape of exterior window cleaning</p>

                    </Link>
                    <div className="service-action-card" data-aos="fade-up" data-aos-delay="200">
                        <div className="service-action-header">
                            <h2>More Services</h2>
                            <ul>
                                <li>
                                    <Link to="/window-cleaning">Window Cleaning</Link>
                                </li>
                                <li>
                                    <Link to="/parking-lot-cleaning">Parking Lot Cleaning</Link>
                                </li>
                                <li>
                                    <Link to="/residential-home">Residential & Home</Link>
                                </li>
                                <li>
                                    <Link to="/roof-cleaning">Roof Cleaning</Link>
                                </li>
                                <li>
                                    <Link to="/monument-restoration">Monument Restoration</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="service-action-footer">
                            <Link className="button button-primary" to={'/commercial-industrial'}>View All Services</Link>
                            <Link onClick={
                                () => {
                                    analyticsCall({
                                        category: "Book a Time - Click",
                                        action: "Book a Time Service Card",
                                    })
                                }
                            } className="button-flat" to="/contact">Book a Time</Link>
                        </div>
                    </div>
                </div>
            </div>


        </>
    );
}

export default Services