import { useSelector } from "react-redux"
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ReactBeforeSliderComponent from 'react-before-after-slider-component';
import { useState } from "react";
import CountUp from 'react-countup';
import { useRef } from "react";

const Reviews = () => {


    return (
        <>
            <div className="reviews-container">
                <div className="core-header">
                    <span>Testimonials</span>
                    <h1>Don't Just Take Our Word For It</h1>

                </div>

                <div className="simple-cards">
                    <Link to="https://g.co/kgs/aFhqKr6" target="_blank" >
                        <div className="simple-card">
                            <div className="star-rating">
                                <i className="material-icons" data-aos="fade-down" data-aos-delay="50" data-aos-anchor-placement="bottom-bottom">star</i>
                                <i className="material-icons" data-aos="fade-down" data-aos-delay="100" data-aos-anchor-placement="bottom-bottom">star</i>
                                <i className="material-icons" data-aos="fade-down" data-aos-delay="150" data-aos-anchor-placement="bottom-bottom">star</i>
                                <i className="material-icons" data-aos="fade-down" data-aos-delay="200" data-aos-anchor-placement="bottom-bottom">star</i>
                                <i className="material-icons" data-aos="fade-down" data-aos-delay="250" data-aos-anchor-placement="bottom-bottom">star</i>
                            </div>
                            <h3>Dr Jared Davis</h3>
                            <p>
                                "Great service quality and very professional. Timely and high quality communication with results"
                            </p>
                        </div>
                    </Link>
                    <Link to="https://g.co/kgs/3B9j8Un" target="_blank">
                        <div className="simple-card">
                            <div className="star-rating">
                                <i className="material-icons" data-aos="fade-down" data-aos-delay="300" data-aos-anchor-placement="bottom-bottom">star</i>
                                <i className="material-icons" data-aos="fade-down" data-aos-delay="350" data-aos-anchor-placement="bottom-bottom">star</i>
                                <i className="material-icons" data-aos="fade-down" data-aos-delay="400" data-aos-anchor-placement="bottom-bottom">star</i>
                                <i className="material-icons" data-aos="fade-down" data-aos-delay="450" data-aos-anchor-placement="bottom-bottom">star</i>
                                <i className="material-icons" data-aos="fade-down" data-aos-delay="500" data-aos-anchor-placement="bottom-bottom">star</i>
                            </div>
                            <h3>Vince Anderson</h3>
                            <p>
                                "These guys are the definition of professional! The tech is amazing! They cleaned places I’d never be able to reach. Saved me thousands in equipment."
                            </p>
                        </div>
                    </Link>
                    <Link to="https://g.co/kgs/T7q3pGf" target="_blank" >
                        <div className="simple-card">
                            <div className="star-rating">
                                <i className="material-icons" data-aos="fade-down" data-aos-delay="550" data-aos-anchor-placement="bottom-bottom">star</i>
                                <i className="material-icons" data-aos="fade-down" data-aos-delay="600" data-aos-anchor-placement="bottom-bottom">star</i>
                                <i className="material-icons" data-aos="fade-down" data-aos-delay="650" data-aos-anchor-placement="bottom-bottom">star</i>
                                <i className="material-icons" data-aos="fade-down" data-aos-delay="700" data-aos-anchor-placement="bottom-bottom">star</i>
                                <i className="material-icons" data-aos="fade-down" data-aos-delay="750" data-aos-anchor-placement="bottom-bottom">star</i>
                            </div>
                            <h3>Margaret Collins</h3>
                            <p>
                                "Very prompt and thorough!! Job well done! Makes such a noticeable difference! I’m so pleased with the results and can highly recommend Contract Drone Service!"
                            </p>
                        </div>
                    </Link>
                </div>
                <Link to="https://www.google.com/search?q=flybyshine&oq=flybyshine+&gs_lcrp=EgZjaHJvbWUyBggAEEUYOTIGCAEQIxgnMgoIAhAAGIAEGKIEMgoIAxAAGIAEGKIEMgYIBBBFGDwyBggFEEUYPDIGCAYQRRg8MgYIBxBFGDzSAQgyNTg3ajBqNKgCALACAQ&sourceid=chrome&ie=UTF-8#lrd=0x4c4cea8e39d0b969:0x237a5178b08da497,1,,,," className="google-reviews" target="_blank">
                    <img src="/assets/googleReviewsLogo.png" alt="Google Reviews Icon/Button" />
                </Link>
            </div>


        </>
    );
}

export default Reviews